var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('go-back-btn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":true}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersResultsLoteDeuda,"items":_vm.comprobantesLoteDeuda,"hide-default-footer":true,"dense":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.importe)))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v("Lote de deuda N° "+_vm._s(_vm.loteDeudaId + " - " + _vm.nombre))])],1)]},proxy:true},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',{staticClass:"totales text-end"},_vm._l((headers),function(header,i){return _c('td',{key:i},[(header.value == 'entidadFinancieraNombre')?_c('div',[_vm._v(" Total ")]):_vm._e(),(header.value == 'importe')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.addDecimals(_vm.totalImporte)))])]):_vm._e(),(header.value == 'cantidad')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.totalCantidad))])]):_c('div')])}),0)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersEnviosRealizados,"items":_vm.enviosRealizados,"hide-default-footer":true,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v("Envíos realizados")])],1)]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDestinos,"items":_vm.envioConfig,"hide-default-footer":true,"dense":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.importe)))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v("Posibles destinos")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.sendLoteDeuda(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.sendFileIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Enviar")])])]}}],null,true)})],1)],1),(_vm.modalVariableXPantalla)?_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal()}},model:{value:(_vm.modalVariableXPantalla),callback:function ($$v) {_vm.modalVariableXPantalla=$$v},expression:"modalVariableXPantalla"}},[_c('div',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"primary--text"},[_vm._v("Completar datos pedidos por pantalla")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersPantalla,"item-key":"id","dense":"","hide-default-footer":"","items":_vm.datosPantalla},scopedSlots:_vm._u([{key:"item.campoValor",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-edit-dialog',{attrs:{"large":"","cancel-text":"Cancelar","save-text":"Guardar","persistent":""},on:{"open":function($event){return _vm.openEditDialog(item.campoValor)},"save":function($event){return _vm.saveEditValor(item)},"cancel":function($event){return _vm.cancelEditValor(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{ref:"edit-valor",attrs:{"rules":_vm.rules.required.concat([
                              _vm.rules.requiredTrim(item.campoValor)
                            ]),"single-line":"","label":"Editar valor"},model:{value:(item.campoValor),callback:function ($$v) {_vm.$set(item, "campoValor", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.campoValor"}})]},proxy:true}],null,true),model:{value:(item.campoValor),callback:function ($$v) {_vm.$set(item, "campoValor", $$v)},expression:"item.campoValor"}},[_vm._v(" "+_vm._s(item.campoValor)+" "),_c('v-icon',{attrs:{"color":item.campoValor != null ? 'primary' : 'error',"size":"16"}},[_vm._v(_vm._s(_vm.editIcon))])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Editar valor")])])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","tabindex":"-1"},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"type":"submit","form":"crud-form","color":"primary","tabindex":"-1"},on:{"click":function($event){return _vm.enviarLoteWithVariables()}}},[_vm._v(" Enviar ")])],1)],1)],1)]):_vm._e(),(_vm.modalExportCsv)?_c('v-dialog',{attrs:{"max-width":"570px","persistent":""},model:{value:(_vm.modalExportCsv),callback:function ($$v) {_vm.modalExportCsv=$$v},expression:"modalExportCsv"}},[_c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"headline primary--text"},[_vm._v("Se generará un archivo con los datos del envío.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBtnFalse),expression:"showBtnFalse"}],attrs:{"tabindex":"-1"}}),_c('v-btn',{staticClass:"py-0",attrs:{"color":"primary","tabindex":"-1"},on:{"click":function($event){return _vm.closeModal()}}},[(_vm.macroLink)?_c('vue-excel-xlsx',{staticClass:"to-right",attrs:{"data":_vm.resultsLoteDeuda,"columns":_vm.columns,"filename":_vm.resultsLoteDeuda[0].titulo}},[_vm._v(" Continuar ")]):_vm._e(),(_vm.credicoop)?_c('download-csv',{attrs:{"data":_vm.csvJsonToExport,"name":_vm.nombreArchivo}},[_vm._v(" Continuar ")]):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }